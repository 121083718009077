<template>
	<div class="toolbar-container">
		<slot></slot>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ToolbarContainer',
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.toolbar-container {
	background: $myndshft-orange-200;
	height: 40px;
	width: 100vw;
}
</style>
