<template>
	<span>
		<font-awesome-icon class="icon" icon="search" />
		<input type="text" v-model.trim="searchText" placeholder="Search..." />
		<font-awesome-icon v-if="searchText.length" icon="times" @click="clearValue" />
	</span>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
	name: 'search-bar',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
	},
	props: {
		value: {
			type: String,
			default: () => '',
		},
	},
	setup(props, context) {
		const searchText = ref<string>(props.value)
		const clearValue = () => (searchText.value = '')

		watch(
			() => props.value,
			(searchValue) => {
				searchText.value = searchValue
			}
		)
		watch(searchText, (searchText) => context.emit('search', searchText))

		return { searchText, clearValue }
	},
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

span {
	width: 100%;
	display: flex;
	align-items: center;
	background: $myndshft-gray-900;
	padding: 4px 16px;
	border-radius: 8px;
}

.icon {
	color: $myndshft-gray-600;
	font-size: 20px;
	padding-right: 5px;
}

input {
	width: 100%;
	padding-top: 2px;
	background: $myndshft-gray-900;
	font-size: 15px;
	color: $myndshft-gray-400;
	border: none;
	outline: none;
}

::placeholder {
	color: $myndshft-gray-600;
}
</style>
