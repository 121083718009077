<template>
	<div class="typeahead">
		<input
			ref="inputElement"
			@focus="activateTypeahead"
			@blur="deactivateTypeahead"
			@keydown.down.prevent="nextResult"
			@keydown.up.prevent="previousResult"
			@keydown.enter.prevent="keyboardSelect"
			@keydown.tab="deactivateTypeahead"
			@input="onchangeHandler"
			:data-error="error"
			:disabled="disabled"
			v-model="input.name"
			class="form-field"
		/>
		<font-awesome-icon
			@click="clearValue"
			v-if="showCloseIcon"
			icon="times"
			class="close-icon"
			tabindex="-1"
		/>
		<font-awesome-icon
			v-if="!disabled && state === TypeaheadState.IDLE"
			icon="chevron-down"
			class="chevron-down-icon"
			tabindex="-1"
			@click="activateTypeahead"
		/>

		<ul ref="resultsElement" v-if="state === TypeaheadState.FOCUSED" class="typeahead-result-list">
			<li v-if="results.length === 0" class="typeahead-result">No matches found.</li>
			<li
				v-for="(result, index) of results"
				@mousedown="mouseSelect(index)"
				:key="result.value"
				:data-hover="cursor === index"
				class="typeahead-result"
			>
				{{ result.label || result.name || result.value }}
			</li>
		</ul>
		<font-awesome-icon
			@click="deactivateTypeahead"
			v-if="!disabled && state === TypeaheadState.FOCUSED"
			icon="chevron-up"
			class="chevron-up-icon"
			tabindex="-1"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, SetupContext, onBeforeMount } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

enum TypeaheadState {
	IDLE,
	FOCUSED,
}

interface TypeaheadOption {
	label: string | ''
	value: string | ''
	id: string | ''
	name: string | ''
	code: string | ''
}

function matchesOption(option: TypeaheadOption, input: string | null): boolean {
	const label = (input || '').toLowerCase()
	return (
		option?.label?.toLowerCase().includes(label) ||
		option?.name?.toLowerCase().includes(label) ||
		option?.value?.toLowerCase().includes(label)
	)
}

function filterMatchingOptions(results: Array<any>, label: TypeaheadOption) {
	return results
		?.filter((option) => matchesOption(option, label.name))
		.sort((a, b) => {
			return a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : 1
		})
}

function modulo(x: number, n: number) {
	if (n <= 0) {
		throw new Error('modulus must be positive: ' + n)
	}
	return ((x % n) + n) % n
}

export default defineComponent({
	name: 'VueSelect',
	props: {
		value: {
			type: [String, Object, Boolean],
		},
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'label',
		},
		reducer: {
			type: Function,
			default: (option: any) => option,
		},
		error: String,
	},
	components: {
		'font-awesome-icon': FontAwesomeIcon,
	},
	setup(props: any, context: SetupContext) {
		const inputElement = ref<HTMLInputElement | null>(null)
		const resultsElement = ref<HTMLUListElement | null>(null)
		const emptyOption: TypeaheadOption = { label: '', value: '', name: '', id: '', code: '' }

		const input = ref<TypeaheadOption>({ ...emptyOption })
		const cachedInput = ref<TypeaheadOption>({ ...emptyOption })
		const state = ref<TypeaheadState>(TypeaheadState.IDLE)
		const results = computed(() => filterMatchingOptions(props.options, input.value))
		const cursor = ref<number>(0)
		const showCloseIcon = computed(
			() =>
				props.clearable &&
				inputElement.value &&
				(input.value?.name?.length > 0 || inputElement.value?.placeholder?.length > 0)
		)

		onBeforeMount(() => {
			input.value = convertToOption(props.value)
		})

		watch(
			() => props.value,
			() => {
				input.value = convertToOption(props.value)
			}
		)

		function convertToOption(value: TypeaheadOption | string | boolean | null): TypeaheadOption {
			if (value == null) {
				return { ...emptyOption }
			} else if (typeof value === 'string' || typeof value === 'boolean') {
				const option = props.options.find((o: any) => props.reducer(o) === value) || emptyOption
				return {
					...option,
					label: option.label || option.name || option.value || '',
					name: option.label || option.name || option.value || '',
				}
			} else {
				return {
					...value,
					label: value.label || value.name || value.value || '',
					name: value.label || value.name || value.value || '',
				}
			}
		}

		/**
		 * Scrolls to the result highlighted by the keyboard cursor.
		 */
		function scrollToResult() {
			if (resultsElement.value !== null) {
				const children = resultsElement.value?.children
				children[cursor.value].scrollIntoView({ block: 'nearest', inline: 'nearest' })
			}
		}

		/**
		 * Display the typeahead results.
		 */
		function activateTypeahead() {
			state.value = TypeaheadState.FOCUSED
			cachedInput.value = { ...input.value }
			if (inputElement.value) {
				inputElement.value.placeholder = input.value.label || input.value.name || input.value.value
				inputElement.value.focus()
			}
			input.value = { ...emptyOption }
		}

		/**
		 * Hide the typeahead results.
		 */
		function deactivateTypeahead() {
			if (state.value === TypeaheadState.FOCUSED) {
				state.value = TypeaheadState.IDLE
				cursor.value = 0
				input.value =
					JSON.stringify(cachedInput.value) === JSON.stringify({ ...emptyOption })
						? { ...emptyOption }
						: convertToOption(cachedInput.value)

				JSON.stringify(input.value) === JSON.stringify({ ...emptyOption })
					? context.emit('update', null)
					: context.emit('update', props.reducer(input.value))
			}
			context.emit('onchange', input.value)
		}

		/**
		 * Hover over the next typeahead result.
		 */
		function nextResult() {
			if (results.value.length > 0 && resultsElement.value !== null) {
				cursor.value = modulo(cursor.value + 1, results.value.length)
				scrollToResult()
			}
		}

		/**
		 * Hover over the previous typeahead result.
		 */
		function previousResult() {
			if (results.value.length > 0 && resultsElement.value !== null) {
				cursor.value = modulo(cursor.value - 1, results.value.length)
				scrollToResult()
			}
		}

		/**
		 * Selects a typeahead result in reaction to a keyboard input event.
		 */
		function keyboardSelect() {
			if (cursor.value != null) {
				state.value = TypeaheadState.IDLE
				input.value = convertToOption({ ...results.value[cursor.value] })
				// use cached value if current value is empty (invalid)
				input.value =
					input.value.label !== '' && input.value.name !== '' ? input.value : cachedInput.value
				context.emit('update', props.reducer(input.value) || null)

				if (inputElement.value !== null) {
					inputElement.value.blur()
				}
			}
		}

		/**
		 * Selects a typeahead result in reaction to a mouse input event.
		 */
		function mouseSelect(index: number) {
			state.value = TypeaheadState.IDLE
			input.value = convertToOption({ ...results.value[index] })
			// use cached value if current value is empty (invalid)
			input.value =
				input.value.label !== '' && input.value.name !== '' ? input.value : cachedInput.value
			context.emit('update', props.reducer(input.value))

			if (inputElement.value !== null) {
				inputElement.value.blur()
			}
		}

		function clearValue() {
			input.value = { ...emptyOption }
			context.emit('update', null)
			if (inputElement.value) {
				inputElement.value.placeholder = ''
			}
		}

		watch([input], () => {
			// Reset the result cursor to the first item when the input changes.
			if (results.value?.length > 0 && resultsElement.value !== null) {
				cursor.value = 0
				scrollToResult()
			}
		})

		const onchangeHandler = (e: any) => {
			context.emit('onchange', e?.target?.value)
		}

		return {
			TypeaheadState,

			// Element Refs
			inputElement,
			resultsElement,

			// Refs
			input,
			results,
			state,
			cursor,
			showCloseIcon,

			// Methods
			activateTypeahead,
			deactivateTypeahead,
			nextResult,
			previousResult,
			keyboardSelect,
			mouseSelect,
			clearValue,
			onchangeHandler,
		}
	},
})
</script>

<style lang="scss" scoped>
@import '../../../node_modules/@myndshft/color-palette/src/colors.scss';
@import '@/styles/colors.scss';

.typeahead {
	position: relative;
}

.typeahead-result-list {
	position: absolute;
	max-height: 200px;
	overflow-y: scroll;
	border-radius: 4px;
	width: 100%;
	z-index: 100;
	border-bottom: 1px solid $myndshft-gray-900;
	border-left: 1px solid $myndshft-gray-900;
	border-right: 1px solid $myndshft-gray-900;
}

.typeahead-result {
	background: white;
	font-size: 14px;
	padding: 8px 16px;
	user-select: none;
	word-wrap: break-word;

	&:hover {
		background: #ccc;
	}

	&[data-hover='true'] {
		background: #ccc;
	}
}

.form-field {
	width: 100%;
	padding-right: 40px;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:focus {
		outline: none;
	}
}

.form-field[data-error] {
	border: 1px solid $myndshft-required-pink;
	background: #ffe6e6;
	&:focus {
		box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
	}
}

.chevron-down-icon,
.chevron-up-icon {
	position: absolute;
	right: 5px;
	height: 100%;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.close-icon {
	position: absolute;
	right: 25px;
	height: 100%;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}
</style>
