
import { defineComponent, ref, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
	name: 'search-bar',
	components: {
		'font-awesome-icon': FontAwesomeIcon,
	},
	props: {
		value: {
			type: String,
			default: () => '',
		},
	},
	setup(props, context) {
		const searchText = ref<string>(props.value)
		const clearValue = () => (searchText.value = '')

		watch(
			() => props.value,
			(searchValue) => {
				searchText.value = searchValue
			}
		)
		watch(searchText, (searchText) => context.emit('search', searchText))

		return { searchText, clearValue }
	},
})
