import { Ref, computed, ref, onMounted, onUnmounted } from 'vue'
import { toPx, translate, scale } from '@/modules/helpers/css'
import { Coordinates, ModalConfig } from './types'

import { flow } from 'fp-ts/lib/function'
import * as A from 'fp-ts/lib/Array'

/**
 * Computes the size of a modal given some configuration and the dimensions of
 * the viewport. Returns `windowHeight`, `windowWidth`, `windowCenter`,
 * `modalWidth`, and `modalHeight`.
 *
 * ```
 *           +----------------------------------------------+ -+-
 *           |..............................................|  | Minimum
 *           |..............................................|  | Padding
 *           |....+------------------------------------+....| -+-
 *           |....|                                    |....|
 *           |....|                                    |....|
 *           |....|                                    |....|
 *   Window  |....|                                    |....|
 *  Viewport |....|  Modal                             |....|
 *   Height  |....|  Height          *                 |....|
 *           |....|                Center              |....|
 *           |....|                                    |....|
 *           |....|                                    |....|
 *           |....|             Modal Width            |....|
 *           |....|                                    |....|
 *           |....+------------------------------------+....| -+-
 *           |..............................................|  | Minimum
 *           |..............................................|  | Padding
 *           +----------------------------------------------+ -+-
 *
 *                        Window Viewport Width
 * ```
 */
export function useModalSize(config: ModalConfig) {
	// Window viewport dimensions
	const windowSize = useWindowSize()
	const windowWidth = windowSize.width.value
	const windowHeight = windowSize.height.value
	const windowCenter = computed<Coordinates>(() => ({
		x: windowWidth / 2,
		y: windowHeight / 2,
	}))

	// Helper functions
	const applyPadding = (length: number) => length - config.padding * 2
	const minimum = (xs: number[]) => Math.min(...xs)
	const smallest = flow(A.map(applyPadding), minimum)

	// Modal dimensions
	const modalWidth = computed(() => smallest([windowWidth, config.maxWidth]))
	const modalHeight = computed(() => smallest([windowHeight, config.maxHeight]))
	const modalCenter = computed<Coordinates>(() => ({
		x: -modalWidth.value / 2,
		y: -modalHeight.value / 2,
	}))

	return {
		windowHeight,
		windowWidth,
		windowCenter,
		modalWidth,
		modalHeight,
		modalCenter,
	}
}

/**
 * Computes modal styles for each transition state.
 */
export function useModalStyles(
	modalWidth: Ref<number>,
	modalHeight: Ref<number>,
	modalCenter: Ref<Coordinates>,
	windowCenter: Ref<Coordinates>,
	clickOrigin: Ref<Coordinates>,
	config: ModalConfig
) {
	const containerStyles = computed(() => ({
		enter: {
			transform: translate(toPx(clickOrigin.value.x), toPx(clickOrigin.value.y)),
			transition: 'none',
		},
		target: {
			transform: translate(toPx(windowCenter.value.x), toPx(windowCenter.value.y)),
			transition: 'all 500ms ease-out',
		},
		leave: {
			transform: translate(toPx(windowCenter.value.x), toPx(windowCenter.value.y)),
			transition: 'all 500ms ease-out',
		},
	}))

	const contentStyles = computed(() => ({
		enter: {
			opacity: 1,
			width: 0,
			height: 0,
			left: 0,
			top: 0,
			transform: scale(0),
			transition: 'none',
		},
		target: {
			opacity: 1,
			width: toPx(modalWidth.value),
			height: toPx(modalHeight.value),
			left: toPx(modalCenter.value.x),
			top: toPx(modalCenter.value.y),
			transform: scale(1),
			transition: 'all 500ms ease-out',
		},
		leave: {
			opacity: 0,
			width: toPx(modalWidth.value),
			height: toPx(modalHeight.value),
			left: toPx(modalCenter.value.x),
			top: toPx(modalCenter.value.y + config.padding),
			transform: scale(1),
			transition: 'all 300ms ease-out',
		},
	}))

	return {
		containerStyles,
		contentStyles,
	}
}

function useWindowSize() {
	const width = ref(window.innerWidth)
	const height = ref(window.innerHeight)

	const onResize = () => {
		width.value = window.innerWidth
		height.value = window.innerHeight
	}

	onMounted(() => window.addEventListener('resize', onResize))
	onUnmounted(() => window.addEventListener('resize', onResize))

	return {
		width: computed(() => width.value),
		height: computed(() => height.value),
	}
}
