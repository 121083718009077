/**
 * Converts a `number` to a CSS string value with pixel units.
 */
export const toPx = (units: number) => units + 'px'

/**
 * Converts a list of arguments to a string-representation of the `translate`
 * CSS function.
 *
 * ``` ts
 * import { translate } from '@/modules/helpers/css'
 *
 * const styles = {
 *   transform: translate('50%', '200px')
 * }
 * ```
 */
export const translate = (...args: (string | 0)[]) => `translate(${args.join(', ')})`

/**
 * Converts a list of arguments to a string-representation of the `scale` CSS
 * function.
 *
 * ``` ts
 * import { scale } from '@/modules/helpers/css'
 *
 * const styles = {
 *   transform: scale(1, 2)
 * }
 * ```
 */
export const scale = (...args: (string | number)[]) => `scale(${args.join(', ')})`
