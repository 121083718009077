<template>
	<div>
		<teleport to="#modal-overlay">
			<div
				class="modal-container"
				v-if="isActive"
				:style="containerStyles[transitionState]"
				ref="container"
			>
				<div
					class="modal-content"
					:style="contentStyles[transitionState]"
					@click.stop
					@mousedown.stop
					@mouseup.stop
				>
					<slot></slot>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onBeforeUnmount } from 'vue'
import { ModalConfig, TransitionState } from './types'
import { useModalSize, useModalStyles } from './hooks'
import { modalService } from './machine'
import { useService } from '@xstate/vue'
import { v4 as uuidv4 } from 'uuid'

import { UUID } from 'io-ts-types/lib/UUID'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import ModalOverlay from './Overlay'

export default defineComponent({
	name: 'Modal',
	components: {
		[ModalOverlay.name]: ModalOverlay,
	},
	props: {
		maxWidth: {
			type: Number,
			default: 960,
		},
		maxHeight: {
			type: Number,
			default: 540,
		},
		padding: {
			type: Number,
			default: 24,
		},
		overlayClickHandler: {
			type: Function,
		},
	},
	emits: ['show', 'hide'],
	setup(props, context) {
		const config = computed<ModalConfig>(() => {
			return {
				maxWidth: props.maxWidth,
				maxHeight: props.maxHeight,
				padding: props.padding,
			}
		})
		// Every modal needs a unique identifier to enforce the property that, at
		// most, *one* modal may be visible at any given time.
		const id = ref<UUID>(uuidv4() as UUID)

		const { state, send } = useService(modalService)

		const clickOrigin = computed(() =>
			pipe(
				state.value.context.from,
				O.getOrElse(() => ({ x: 0, y: 0 }))
			)
		)

		const isActive = computed(() =>
			pipe(
				state.value.context.id,
				O.getOrElse(() => 'none'),
				(uuid) => uuid === id.value
			)
		)

		const showHandler = (e: MouseEvent) => {
			send({
				type: 'SHOW',
				id: id.value,
				from: {
					x: e.clientX,
					y: e.clientY,
				},
			})
		}

		const hideHandler = () => {
			send({ type: 'HIDE' })
		}

		watch(isActive, (isActive) => {
			if (isActive) {
				context.emit('show')
			} else {
				context.emit('hide')
			}
		})

		const {
			// Viewport dimensions
			windowCenter,

			// Modal dimensions
			modalWidth,
			modalHeight,
			modalCenter,
		} = useModalSize(config.value)

		const { containerStyles, contentStyles } = useModalStyles(
			modalWidth,
			modalHeight,
			modalCenter,
			windowCenter,
			clickOrigin,
			config.value
		)

		const transitionState = ref<TransitionState>('enter')
		const container = ref<HTMLElement | null>(null)

		let timeout: number | null = null
		watch(
			() => state.value.value,
			(state) => {
				if (isActive.value) {
					if (state === 'opening') {
						transitionState.value = 'enter'

						// Vue uses an asynchronous queue to batch DOM updates, so here,
						// we are going to push the next update to the macro task queue to
						// give the UI a chance to render.
						if (timeout) clearTimeout(timeout)
						timeout = setTimeout(() => {
							transitionState.value = 'target'

							if (container.value) {
								container.value.ontransitionend = () => {
									send('SHOW_TRANSITION_COMPLETE')
								}
							}
						}, 50)
					} else if (state === 'closing') {
						if (timeout) clearTimeout(timeout)
						transitionState.value = 'leave'

						if (container.value) {
							container.value.ontransitionend = () => {
								send('HIDE_TRANSITION_COMPLETE')
							}
						}
					} else if (state === 'overlay') {
						if (props.overlayClickHandler) {
							props.overlayClickHandler()
						} else {
							send('HIDE')
						}
					}
				}
			}
		)

		onBeforeUnmount(() => {
			if (timeout) clearTimeout(timeout)
		})

		return {
			container,
			showHandler,
			hideHandler,

			modalWidth,
			modalHeight,

			transitionState,
			containerStyles,
			contentStyles,

			id,
			state,
			isActive,
		}
	},
})
</script>

<style lang="scss" scoped>
.modal-container {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1000;
	will-change: transform, opacity;
}

.modal-content {
	position: absolute;
	background: white;
	z-index: 1000;
	overflow: auto;
	will-change: transform, opacity;

	border-radius: 8px;
	box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3),
		0 -18px 60px -10px rgba(0, 0, 0, 0.025);
}
</style>
